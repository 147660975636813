import { ToastContainer } from "react-toastify";
import Header from "./header";

const GuestLayout = ({ children }) => {
  let body = null;
  const containerStyle = {
    backgroundImage: `url('/images/scattered-forcefields.svg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    backgroundAttachment: "fixed",
  };

  body = (
    <main style={containerStyle}>
      <div className="main-content position-relative border-radius-lg">
        <div className="container-fluid py-4 ">
          <div className="row ">{children}</div>
        </div>
      </div>
    </main>
  );

  return (
    <>
      <Header />

      {body}
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </>
  );
};

export default GuestLayout;
