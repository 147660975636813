import Head from "next/head";
import { useSettings } from "@hooks/settings";

const Header = () => {
  const settings = useSettings();

  return (
    <Head>
      <title>{settings.app_name}</title>
      <meta name="description" content={settings.app_name} />
      <link rel="icon" href={settings.app_favicon} />
    </Head>
  );
};

export default Header;
