import React from "react";
import Header from "./header";
import Footer from "./navigation/Footer";
import TopNav from "./navigation/TopNav";
import Sidebar from "./navigation/Sidebar";
import { ToastContainer } from "react-toastify";

const AuthLayout = ({ children, showMobilemenu }) => {
  let body = null;

  body = (
    <main className="d-flex flex-column min-vh-100">
      {/********topnav**********/}
      <TopNav showMobmenu={() => showMobilemenu()} />

      {/******** Sidebar **********/}
      <aside
        className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 fixed-start"
        id="sidenav-main"
        style={{ zIndex: 999 }}
      >
        <Sidebar showMobilemenu={() => showMobilemenu()} />
      </aside>

      {/********Content Area**********/}
      <div
        className="main-content position-relative border-radius-lg"
        id="main-content"
      >
        {/********Middle Content**********/}
        <div className="container-fluid py-4">{children}</div>
      </div>

      {/********Footer**********/}
      <Footer />
    </main>
  );

  return (
    <>
      <Header />

      {body}
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </>
  );
};

export default AuthLayout;
