import useSWR from "swr";

async function fetcher(...urls) {
  const f = (u) =>
    fetch(u).then((r) => {
      if (!r.ok) {
        const error = new Error("An error occurred while fetching the data.");
        error.info = r.json();
        error.status = r.status;
        throw error;
      }
      return r.json();
    });

  if (urls.length > 1) {
    return Promise.all(urls.map(f));
  }
  return f(urls);
}

export default function useFetcher(...url) {
  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    onErrorRetry: (error) => {
      if (error.status === 404) return;
    },
  });

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
