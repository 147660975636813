import { useSettings } from "@hooks/settings";
import Image from "next/image";
import Link from "next/link";

const Logo = () => {
  const settings = useSettings();

  return (
    <Link href="/" passHref className="navbar-brand">
      <Image
        src={settings.app_logo}
        alt={settings.app_name}
        width={100}
        height={100}
        className="navbar-brand-img mt-2"
        style={{ objectFit: "contain" }}
      />
    </Link>
  );
};

export default Logo;
