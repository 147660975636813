import React, { useState, useEffect } from "react";

function ErrorBoundary(props) {
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    function componentDidCatch(error, errorInfo) {
      setError(error);
      setErrorInfo(errorInfo);
      setHasError(true);
    }

    window.addEventListener("error", componentDidCatch);

    return () => {
      window.removeEventListener("error", componentDidCatch);
    };
  }, []);

  if (hasError) {
    return (
      <div className="card my-5">
        <div className="card-header">
          <p>
            There was an error in loading this page.
            <span
              style={{ cursor: "pointer", color: "#0077FF" }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload this page
            </span>
          </p>
        </div>
        <div className="card-body">
          <details className="error-details">
            <summary>Click for error details</summary>
            {errorInfo && errorInfo.componentStack.toString()}
          </details>
        </div>
      </div>
    );
  }

  return props.children;
}

export default ErrorBoundary;
