import { useTranslation } from "next-i18next";
import { useSettings } from "@hooks/settings";
const { version } = require("../../../package.json");

const Footer = () => {
  const { t } = useTranslation("common");
  const settings = useSettings();

  return (
    <footer className="text-center bg-white mt-auto">
      {/* <div className="container pt-4">
        <section className="mb-4">
          <a
            className="btn btn-link btn-floating btn-lg text-body m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-facebook-f" />
          </a>

          <a
            className="btn btn-link btn-floating btn-lg text-body m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-twitter" />
          </a>

          <a
            className="btn btn-link btn-floating btn-lg text-body m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-instagram" />
          </a>

          <a
            className="btn btn-link btn-floating btn-lg text-body m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-linkedin" />
          </a>
        </section>
      </div> */}

      <div className="text-center text-dark p-3">
        {`© ${new Date().getFullYear()} ${t("footer.copy-right")} ${
          settings.app_name
        } ${t("footer.all-rights-reserved")} `}
      </div>

      {/* show version from package.json */}
      <div className="text-center text-dark p-3">{`V${version}`}</div>
    </footer>
  );
};

export default Footer;
